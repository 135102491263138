import { ChevronDownIcon } from '@heroicons/react/24/outline'

import classes from '../PreviewCurriculum.module.css'
import Svgs from 'Assets/Svgs'

interface IProps {
  curriculumTitle: string
  courseModules?: IModule[]
  currentLesson?: ILesson
  currentModule?: IModule
  setCurrentModule: (value: any) => void
  setCurrentLesson: (value: any) => void
}

interface ILesson {
  id: number
  title: string
  type: string
  content: string
}

interface IModule {
  id: number
  moduleTitle: string | null
  lessons?: (ILesson | undefined)[]
}

const Sidebar = (props: IProps) => {
  const {
    courseModules,
    currentLesson,
    currentModule,
    setCurrentLesson,
    setCurrentModule,
    curriculumTitle,
  } = props

  const getLessonIcon = (lesson?: ILesson) => {
    if (!lesson?.type) {
      return null
    }

    return {
      reading: <Svgs.ReadingIcon />,
      presentation: <Svgs.PresentationIcon />,
      video: <Svgs.VideoIcon />,
      customize: <Svgs.ReadingIcon />,
      quiz: <Svgs.AssessmentToolsIcon />,
      assignment: <Svgs.AssessmentToolsIcon />,
      final_exam: <Svgs.AssessmentToolsIcon />,
    }[lesson?.type]
  }

  return (
    <div className={classes.leftSide}>
      <div className={classes.top}>
        <h4>{curriculumTitle}</h4>
      </div>

      <div className={classes.bottom}>
        {courseModules?.map((item) => (
          <>
            <div className={classes.moduleTitle}>
              <h4>{item.moduleTitle}</h4>

              <ChevronDownIcon />
            </div>

            {item?.lessons?.map((lesson) => (
              <div
                className={classes.week}
                onClick={() => {
                  setCurrentLesson(lesson)
                  setCurrentModule(item)
                }}
              >
                <div className={classes.courseFile}>
                  <p
                    className={
                      currentLesson?.id === lesson?.id
                        ? '!text-[#2E2E2E] !font-medium'
                        : '!text-[#737373]'
                    }
                  >
                    {lesson?.title}
                  </p>
                  <p>
                    <span>{lesson?.type}</span> .<span>5min</span>
                  </p>
                </div>

                <div
                  className={
                    currentLesson?.id === lesson?.id
                      ? 'text-[#FDC500]'
                      : 'text-[#737373]'
                  }
                >
                  {getLessonIcon(lesson)}
                </div>
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  )
}

export default Sidebar
