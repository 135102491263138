import Reading from './Reading'
import { useSearchParams } from 'react-router-dom'
import Video from './Video'
import Presentation from './Presentation'
import Customize from './Customize'
import Quiz from './Quiz'
import Assignment from './Assignment'
import FinalExam from './FinalExam'

const LessonType = () => {
  enum LessonTypes {
    READING = 'reading',
    VIDEO = 'video',
    PRESENTATION = 'presentation',
    CUSTOMIZE = 'customize',
    QUIZ = 'quiz',
    ASSIGNMENT = 'assignment',
    FINAL_EXAM = 'final-exam',
  }

  const [searchParams] = useSearchParams()

  const lessonType = searchParams.get('lesson-type')

  return (
    <>
      {LessonTypes.READING === lessonType && <Reading />}
      {LessonTypes.VIDEO === lessonType && <Video />}
      {LessonTypes.PRESENTATION === lessonType && <Presentation />}
      {LessonTypes.CUSTOMIZE === lessonType && <Customize />}
      {LessonTypes.QUIZ === lessonType && <Quiz />}
      {LessonTypes.ASSIGNMENT === lessonType && <Assignment />}
      {LessonTypes.FINAL_EXAM === lessonType && <FinalExam />}
    </>
  )
}

export default LessonType
