import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../../Context/AuthUserContext'
import Loader from '../Loader/Loader'

const RequireAuth = () => {
  // Local storage
  const userToken = localStorage.getItem('iseTutorAccessToken')
  // Location
  const location = useLocation()

  // Context
  const { isLoading } = useAuth()

  if (!userToken) {
    return <Navigate to="/sign-in" replace={true} state={location.pathname} />
  }

  if (isLoading) return <Loader />

  return <Outlet />
}

export default RequireAuth
