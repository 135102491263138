import { axiosInstance, TNullable, TTutor } from 'Services'
import endpoints from './endpoints'

type TSchool = {
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  name: TNullable<string>
  description: TNullable<string>
  image: TNullable<string>
  position: TNullable<number>
  tagline: TNullable<string>
  tagline_title: TNullable<string>
  facts: TNullable<string>
  salary_range: TNullable<string>
  salary_range_text: TNullable<string>
  career_path_no: TNullable<string>
  subtext: TNullable<string>
  facts_cover_image: TNullable<string>
  slug: TNullable<string>
  status: TNullable<string>
}

type TCourse = {
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  name: TNullable<string>
  cover_image: TNullable<string>
  overview: TNullable<string>
  description: TNullable<string>
  website_title: TNullable<string>
  slug: TNullable<string>
  position: TNullable<number>
  course_objective: TNullable<string>
  difficulty_level: TNullable<string>
  publish: true
  price: TNullable<number>
  discount_percentage: TNullable<number>
  target_audiences: TNullable<
    {
      category: string
      description: string
    }[]
  >
  updated_at: TNullable<string>
  school: TSchool
  curriculums: TNullable<TCurriculum[]>
}

export type TTutorCourse = {
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  name: TNullable<string>
  commitment: TNullable<string>
  location: TNullable<string>
  certification: boolean
  start_date: TNullable<string>
  end_date: TNullable<string>
  duration: TNullable<string>
  price: TNullable<number>
  total_capacity: TNullable<number>
  has_installment: boolean
  paid: boolean
  application_deadline: TNullable<string>
  slug: TNullable<string>
  status: TNullable<string>
  has_discount: boolean
  discount_percentage: TNullable<number>
  coupon_code: TNullable<string>
  course: TCourse
}

export type TCurriculum = {
  id: TNullable<number>
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  title: TNullable<string>
  course_modules: TCourseModule[]
}

export type TCourseModule = {
  title: TNullable<string>
  objective: string[]
  description: TNullable<string>
  curriculums: TNullable<TCurriculum>
  tutor: TNullable<TTutor>
  slug: TNullable<string>
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  position: number
  publish: boolean
  status: TNullable<string>
  course_weeks: TCourseWeek[]
}

export type TCourseWeek = {
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  title: TNullable<string>
  publish: boolean
  course_modules?: TCourseModule
  content?: TLessonContent[]
}

export type TLessonContentDetails = {
  id: number
  created_at: string
  update_at: string
  deleted_at: null
  title: string
  reading_contents: string
  video_url: string
  blocks: { type: string; value: string }[]
  instructions: string
  link: string
  position: number
  resources: string
  lesson_content_type: string
  publish: boolean
  file_urls?: any
}

export type TLessonContent = {
  id: number
  created_at: string
  update_at: string
  deleted_at: null | string
  title: string
  icon_image: null
  type: string
  position: number
  details: TLessonContentDetails
}

export const addCourseModule = (
  courseId: string,
  payload: {
    title: string
    objective: string[]
    description: string
  }
) => {
  if (!courseId) throw new Error('Course id is required')

  return axiosInstance.post<TCourseModule>(
    endpoints.COURSE_MODULE(courseId),
    payload
  )
}

export const updateCourseModule = (
  courseId: string,
  payload: {
    title: string
    objective: string[]
    description: string
  }
) => {
  if (!courseId) throw new Error('Course id is required')

  return axiosInstance.put<TCourseModule>(
    endpoints.COURSE_MODULE(courseId),
    payload
  )
}

export const addCourseWeek = (
  moduleId: string,
  payload: {
    title: string
  }
) => {
  return axiosInstance.post<TCourseWeek>(
    endpoints.ADD_COURSE_WEEKS(moduleId),
    payload
  )
}

export const updateCourseWeek = (
  weekId: string,
  payload: {
    title: string
  }
) => {
  return axiosInstance.patch<TCourseWeek>(
    endpoints.UPDATE_COURSE_WEEKS(weekId),
    payload
  )
}

export const getCourseModuleById = (moduleId: string) => {
  return axiosInstance.get<TCourseModule>(
    endpoints.GET_COURSE_MODULE_BY_ID(moduleId)
  )
}

export const getCourseWeekById = (weekId: string) => {
  return axiosInstance.get<TCourseWeek>(endpoints.GET_COURSE_WEEK_BY_ID(weekId))
}

export const deleteCourseWeekById = (weekId: string) => {
  return axiosInstance.delete(endpoints.DELETE_COURSE_WEEK_BY_ID(weekId))
}

export const deleteCourseModuleById = (moduleId: string) => {
  return axiosInstance.delete(endpoints.DELETE_COURSE_MODULE_BY_ID(moduleId))
}

export const addReadingContentToWeekById = (
  weekId: string,
  payload: FormData
) => {
  return axiosInstance.post(endpoints.READING_CONTENT(weekId), payload)
}

export const updateReadingContentById = (
  lessonId: string,
  payload: FormData
) => {
  return axiosInstance.patch(endpoints.READING_CONTENT(lessonId), payload)
}

export const addPresentationContentToWeekById = (
  weekId: string,
  payload: FormData
) => {
  return axiosInstance.post(endpoints.PRESENTATION_CONTENT(weekId), payload)
}

export const updatePresentationContentById = (
  lessonId: string,
  payload: FormData
) => {
  return axiosInstance.patch(endpoints.PRESENTATION_CONTENT(lessonId), payload)
}

export const deleteLesson = (lessonId: string, lessonType: string) => {
  return axiosInstance.delete(endpoints.LESSON_CONTENT(lessonId, lessonType))
}

export const addLessonToWeekById = <T = any>(
  weekId: string,
  lessonType: string,
  payload: any
) => {
  return axiosInstance.post<T>(
    endpoints.LESSON_CONTENT(weekId, lessonType),
    payload
  )
}

export const updateLessonToWeekById = <T = any>(
  lessonId: string,
  lessonType: string,
  payload: any
) => {
  return axiosInstance.patch<T>(
    endpoints.LESSON_CONTENT(lessonId, lessonType),
    payload
  )
}
