import { fetcher } from 'Services'
import endpoints from '../endpoints'
import useSWR from 'swr'
import {
  TCourseModule,
  TCourseWeek,
  TCurriculum,
  TLessonContentDetails,
  TTutorCourse,
} from '..'

const useGetTutorCourses = () => {
  return useSWR(endpoints.GET_TUTOR_COURSES, fetcher<TTutorCourse[]>)
}
const useGetCourseById = (id: string | number) => {
  return useSWR(endpoints.GET_COURSE_BY_ID(id), fetcher)
}
const useGetCurriculumByCourseId = (id: string | number | null) => {
  return useSWR(
    !id ? null : endpoints.GET_CURRICULUM_BY_COURSE_ID(id),
    fetcher<TCurriculum>
  )
}
const useGetCourseModuleById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.GET_COURSE_MODULE_BY_ID(id),
    fetcher<TCourseModule>
  )
}

const useGetCourseWeekById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.GET_COURSE_WEEK_BY_ID(id),
    fetcher<TCourseWeek>
  )
}

const useGetReadingLessonById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.READING_CONTENT(id),
    fetcher<TLessonContentDetails>
  )
}

const useGetPresentationLessonById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.PRESENTATION_CONTENT(id),
    fetcher<TLessonContentDetails>
  )
}

const useGetVideoLessonById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.VIDEO_CONTENT(id),
    fetcher<
      TLessonContentDetails & {
        video_url: string
        description: string
        thumbnail: File
        embed_url: string
      }
    >
  )
}
const useGetCustomizedLessonById = (id: string | null) => {
  return useSWR(
    !id ? null : endpoints.LESSON_CONTENT(id, 'customized-lesson'),
    fetcher<
      TLessonContentDetails & {
        blocks: { type: string; value: string }[]
      }
    >
  )
}

const useGetLesson = <T = any>(id: string | null, lessonType: string) => {
  return useSWR(
    !id ? null : endpoints.LESSON_CONTENT(id, lessonType),
    fetcher<T>
  )
}

const useCourse = () => {
  return {
    useGetTutorCourses,
    useGetCourseById,
    useGetCurriculumByCourseId,
    useGetCourseModuleById,
    useGetCourseWeekById,
    useGetReadingLessonById,
    useGetPresentationLessonById,
    useGetVideoLessonById,
    useGetCustomizedLessonById,
    useGetLesson,
  }
}

export default useCourse
