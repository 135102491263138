import Button from 'Components/Button/Button'
import { useNavigate } from 'react-router-dom'

import { TTutorCourse } from 'Services/Courses'

const CourseCard = (props: { tutorCourse: TTutorCourse }) => {
  const { tutorCourse } = props

  const navigate = useNavigate()

  return (
    <div className="bg-white rounded-[20px] py-5 px-6 mt-10">
      <div className="border flex rounded-[10px] py-5 px-5">
        <div className="bg-gray-100 h-[186px] w-[400px] rounded shrink-0">
          <img
            src={tutorCourse.course.cover_image || ''}
            alt="course_image"
            className="w-full h-full object-cover rounded-[10px]"
          />
        </div>

        <div className="ml-6">
          <span className="bg-[#FEF7E5] text-[#C7920B] text-[13px] font-medium rounded px-3 py-1 capitalize">
            {tutorCourse.status}
          </span>

          <h2 className="text-xl font-medium mt-2 mb-2">
            {tutorCourse.course?.name || ''} [{tutorCourse.name}]
          </h2>

          <p>{tutorCourse.course?.description || ''}</p>

          <div className="flex items-center mt-6 gap-x-5">
            <div>
              {!tutorCourse.course.curriculums?.length ? (
                <Button
                  onClick={() =>
                    navigate(
                      `/courses/${tutorCourse.course.id}/create-curricullum`
                    )
                  }
                >
                  Create curriculum
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    navigate(`/courses/${tutorCourse.course.id}?isEditing=true`)
                  }
                >
                  Edit curriculum
                </Button>
              )}
            </div>

            <div>
              <Button
                type="secondary"
                onClick={() => navigate(`/courses/${tutorCourse.course.id}`)}
              >
                View course details
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
