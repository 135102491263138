import DragAndDropInput from 'Components/DragAndDropInput/DragAndDropInput'
import { BlockCard, BlockContent, BlockHeader } from './Block'
import { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import VideoPlayer from 'Components/VideoPlayer/VideoPlayer'
import { uploadFile } from 'Services'

interface ICustomizedLesson {
  title: string
  blocks: { type: string; value: string }[]
}

function useUpload(onComplete?: (data: any) => void) {
  const [progress, setProgress] = useState('')
  const [link, setLink] = useState(null)

  const handleReset = () => {
    setProgress('')
    setLink(null)
  }

  const onClick = async (file: File) => {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const { data } = await uploadFile(formData, {
        onUploadProgress: (progressEvent: any) => {
          const calculateProgress =
            (Number(progressEvent.loaded) / Number(progressEvent.total)) * 100

          setProgress(`${Math.ceil(calculateProgress)}%...`)
        },
      })

      setLink(data.fileUrl)
      onComplete && onComplete(data)
    } catch (err: any) {
      throw new Error(err)
    }
  }

  return { progress, link, onClick, handleReset }
}

const VideoBlock = (props: {
  value: string
  setValue: Dispatch<SetStateAction<ICustomizedLesson>>
  blockIndex: number
}) => {
  const { value, blockIndex, setValue } = props

  const [video, setVideo] = useState<{ frontendFile: string; file: any }>({
    frontendFile: '',
    file: null,
  })
  const [videoData, setVideoData] = useState({ id: '', url: '' })

  const handleDelete = () => {
    setValue((state) => ({
      ...state,
      blocks: state.blocks.filter((_, i) => blockIndex !== i),
    }))
  }

  useEffect(() => {
    setVideoData({ id: '', url: value })
    console.log('======>', value)
  }, [])

  const { onClick, progress, handleReset } = useUpload((data) => {
    setVideoData(data)
    setValue((state) => ({
      ...state,
      blocks: state.blocks.map((block, i) => {
        if (i === blockIndex) {
          return { ...block, value: data.url }
        }

        return block
      }),
    }))
  })

  return (
    <BlockCard>
      <BlockHeader title="Video" onDelete={handleDelete} />

      <BlockContent>
        {!videoData.url && (
          <>
            {!progress ? (
              <>
                <DragAndDropInput
                  acceptedFileTypes=".mov,.mp4,.mpeg,.webm,.m4v"
                  setFile={onClick}
                  type="video"
                />
                <p className="bg-[#FFEDAD] text-[13px] py-2 px-2 rounded mt-2">
                  You can upload files with the extensions: mov, movie, mp4,
                  mpeg, vivo, webm, m4v
                </p>
              </>
            ) : (
              progress
            )}
          </>
        )}

        {videoData.url && (
          <div className="border px-6 pb-5 rounded-md">
            <div className="flex items-center justify-between border-b mb-5 py-5">
              <h3 className="text-xl">Preview video</h3>

              <TrashIcon
                className="text-red-500"
                onClick={() => {
                  // setDisplayDeleteModal(true)
                  // delete video from cloudinary based of the id
                  handleReset()
                  setVideoData({ url: '', id: '' })
                  setValue((state) => ({
                    ...state,
                    blocks: state.blocks.map((block, i) => {
                      if (i === blockIndex) {
                        return { ...block, value: '' }
                      }

                      return block
                    }),
                  }))
                }}
              />
            </div>

            <div className="">
              <VideoPlayer height="200px" url={videoData.url} />
            </div>
          </div>
        )}
      </BlockContent>
    </BlockCard>
  )
}

export default VideoBlock
