import { useSearchParams } from 'react-router-dom'
import SelectLesson from './SelectLesson/SelectLesson'
import LessonIntro from './LessonIntro'
import { useEffect, memo } from 'react'
import LessonType from './LessonType'

const getPreviousSearchParams = (prevSearchParamState: URLSearchParams) => {
  return Object.fromEntries(prevSearchParamState.entries())
}

const CurriculumLesson = () => {
  enum LessonSteps {
    LESSON_INTRO = 1,
    SELECT_LESSON,
    LESSON_TYPE,
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const lessonStep = searchParams.get('lesson-step')

  useEffect(() => {
    if (!lessonStep) {
      setSearchParams((prevState) => {
        const prev = getPreviousSearchParams(prevState)

        return { ...prev, 'lesson-step': '1' }
      })
    }
  }, [])

  return (
    <>
      {LessonSteps.LESSON_INTRO === Number(lessonStep) && <LessonIntro />}
      {LessonSteps.SELECT_LESSON === Number(lessonStep) && <SelectLesson />}
      {LessonSteps.LESSON_TYPE === Number(lessonStep) && <LessonType />}
    </>
  )
}

export default memo(CurriculumLesson)
