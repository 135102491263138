import Loader from 'Components/Loader/Loader'
import EmptyTabComponent from 'Components/EmptyTabComponent/EmptyTabComponent'
import CourseCard from './Components/CourseCard'
import AnalyticsCard from './Components/AnalyticsCard'

import useCourse from 'Services/Courses/Hooks/useCourse'

import IMAGE_CROWD from 'Assets/Images/crowd.png'
import IMAGE_EBOOK from 'Assets/Images/ebook.png'
import IMAGE_EDUCATION from 'Assets/Images/education.png'
import IMAGE_NO_MODULES from 'Assets/Images/noModules.svg'

const Courses = () => {
  const { useGetTutorCourses } = useCourse()
  const { data: response, isLoading } = useGetTutorCourses()

  const analytics = [
    {
      title: 'Assigned courses',
      value: response?.data.length || 0,
      icon: IMAGE_EBOOK,
    },
    { title: 'Total enrolments', value: 0, icon: IMAGE_EDUCATION },
    { title: 'Active learners', value: 0, icon: IMAGE_CROWD },
  ]

  if (isLoading) {
    return <Loader />
  }

  if (!response?.data.length) {
    return (
      <EmptyTabComponent
        image={IMAGE_NO_MODULES}
        imageHeight={300}
        header="No assigned course "
        firstParagraph=" We're actively working on matching you with the perfect course based on your expertise."
        secondParagraph=" You'll receive an email notification once you’re assigned a course."
        route="/"
        buttontext="Create a lesson"
        showButton={false}
      />
    )
  }

  return (
    <div className="px-5 py-5">
      <section className="flex gap-x-6">
        {analytics.map((analytic, i) => (
          <AnalyticsCard
            key={`analytic__${i}`}
            title={analytic.title}
            value={analytic.value}
            icon={analytic.icon}
          />
        ))}
      </section>

      <section>
        {response?.data.map((tutorCourse) => (
          <CourseCard key={tutorCourse.id} tutorCourse={tutorCourse} />
        ))}
      </section>
    </div>
  )
}

export default Courses
