import { useNavigate } from 'react-router-dom'

import Button from 'Components/Button/Button'

import classes from '../PreviewCurriculum.module.css'
import { EyeSlashIcon } from '@heroicons/react/24/outline'

const Header = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.header}>
      <p>You are previewing all course content</p>

      <Button
        type="secondary"
        onClick={() => {
          navigate(-1)
        }}
      >
        <EyeSlashIcon />

        <span>Leave preview</span>
      </Button>
    </div>
  )
}

export default Header
