import { Dispatch, SetStateAction, memo } from 'react'

import RichTextEditor from 'Components/RichTextEditor'
import { BlockCard, BlockContent, BlockHeader } from './Block'

interface ICustomizedLesson {
  title: string
  blocks: { type: string; value: string }[]
}

const TextAndImageBlock = (props: {
  value: string
  setValue: Dispatch<SetStateAction<ICustomizedLesson>>
  blockIndex: number
}) => {
  const { value, setValue, blockIndex } = props

  const handleSetValue = (val: any) => {
    setValue((state) => ({
      ...state,
      blocks: state.blocks.map((block, i) => {
        if (blockIndex === i) {
          return { ...block, value: val }
        }
        return block
      }),
    }))
  }

  const handleDelete = () => {
    setValue((state) => ({
      ...state,
      blocks: state.blocks.filter((_, i) => blockIndex !== i),
    }))
  }

  return (
    <BlockCard>
      <BlockHeader title="Text and images" onDelete={handleDelete} />

      <BlockContent>
        <RichTextEditor value={value} setValue={handleSetValue} />
      </BlockContent>
    </BlockCard>
  )
}

export default memo(TextAndImageBlock)
