import { useEffect, useRef, useState } from 'react'
import Input from 'Components/Input/Input'
import classes from './CourseReading.module.css'
import SectionsNav4 from 'Components/SectionsNav4/SectionsNav4'
import BulkUploadComponent from './BulkUploadComponent'
import BuildFromScratch from './BuildFromScratch'
import { uploadReadingData } from 'Utilities/types'
import { inputChangeHandler } from 'HelperFunctions/inputChangeHandler'
import { mutate } from 'swr'
import { backend_url } from 'Utilities/global'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLessonById } from 'Hooks/useTutorCourses'
import Loader from 'Components/Loader/Loader'
import {
  MDXEditor,
  type MDXEditorMethods,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  ListsToggle,
  InsertFrontmatter,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import Button from 'Components/Button/Button'
import { TrashIcon } from '@heroicons/react/24/outline'
import {
  addReadingContentToWeekById,
  updateReadingContentById,
} from 'Services/Courses'
import errors from 'Utilities/errors'
import { useApp } from 'Context/AppContext'
import endpoints from 'Services/Courses/endpoints'
import useCourse from 'Services/Courses/Hooks/useCourse'

const Reading = () => {
  const ref = useRef<MDXEditorMethods>(null)

  const isLoading = false

  const [readingData, setReadingData] = useState({
    title: '',
    resources: 'www.url',
  })
  const [readingContent, setReadingContent] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { courseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setNotifications } = useApp()
  const { useGetReadingLessonById } = useCourse()

  const weekId = searchParams.get('week-id')
  const lessonId = searchParams.get('lesson-id')
  const { data: response } = useGetReadingLessonById(lessonId)

  useEffect(() => {
    setReadingData((state) => ({ ...state, title: response?.data.title || '' }))
    setReadingContent(response?.data.reading_contents || '')
  }, [response?.data])

  if (isLoading) {
    return (
      <div className={classes.container}>
        <Loader />
      </div>
    )
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      const formData = new FormData()

      Object.entries(readingData).forEach(([key, value]) => {
        formData.append(key, value?.toString() || '')
      })

      formData.append('reading_contents', ref.current?.getMarkdown() || '')

      if (lessonId) {
        await updateReadingContentById(lessonId as string, formData)

        setNotifications([
          {
            title: 'Successfully updated lesson',
            severity: 'success',
            id: Date.now(),
          },
        ])
      } else {
        await addReadingContentToWeekById(weekId as string, formData)

        setNotifications([
          {
            title: 'Successfully added lesson to week',
            severity: 'success',
            id: Date.now(),
          },
        ])
      }

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setSearchParams((prevState) => {
        prevState.delete('lesson-id')
        prevState.delete('lesson-type')
        prevState.set('lesson-step', '1')
        return prevState
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className={classes.container}>
      <h1>{readingData.title || 'Untitled lesson'}</h1>
      <div className={classes.addLesson}>
        <Input
          isRequired
          label="Add lesson title"
          placeholder="Untitled lesson"
          errorMessage="Please enter a valid lesson name"
          name="title"
          value={readingData.title}
          onChange={(e) => inputChangeHandler(e, setReadingData)}
        />
      </div>

      <div className={`${classes.addLesson} ${classes.body}`}>
        <section className={classes.subContainer}>
          <div>
            <div className="bg-white pt-8 pb-10 px-8 rounded-lg">
              <h2 className="text-[25px] font-medium mb-8">
                Add reading content
              </h2>

              <div className="border rounded-md h-[400px]">
                <MDXEditor
                  plugins={[
                    // Example Plugin Usage
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    markdownShortcutPlugin(),
                    toolbarPlugin({
                      toolbarClassName: '!bg-white !border-b !rounded-b-none',
                      toolbarContents: () => (
                        <>
                          <BoldItalicUnderlineToggles />
                          <ListsToggle />
                          <InsertFrontmatter />
                          <UndoRedo />
                        </>
                      ),
                    }),
                  ]}
                  ref={ref}
                  markdown={response?.data.reading_contents || ''}
                  onChange={console.log}
                />
              </div>
            </div>

            <div className="bg-white py-5 flex justify-between px-8 rounded-lg mt-5">
              <button className="flex items-center font-medium text-rose-500 gap-x-3">
                <TrashIcon />
                Delete lesson
              </button>

              <div className="flex gap-x-5">
                <Button type="secondary">Discard changes</Button>
                <Button onClick={handleSubmit} loading={isSubmitting}>
                  Save lesson
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Reading
