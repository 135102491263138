import { useEffect, useState } from 'react'
import Layout from './Layout'
import ProfileInformation from './Components/ProfileInformation/index'
import { completeProfile, getProfile, TTutor } from 'Services'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ERoutes } from 'Router/Routes'
import errors from 'Utilities/errors'
import { useApp } from 'Context/AppContext'
import endpoints from 'Services/endpoints'
import { useAuth } from 'Context/AuthUserContext'

const Onboarding = () => {
  // Context
  const [_, setSearchParams] = useSearchParams()

  // Search Params
  const currentParams = new URLSearchParams(window.location.search)
  const userStep = currentParams.get('step')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()
  const { setNotifications } = useApp()
  const { setUser } = useAuth()

  useEffect(() => {
    if (!userStep) {
      currentParams.set('step', '1')
      setSearchParams(currentParams.toString())
    }

    // eslint-disable-next-line
  }, [userStep])

  const [onboarding, setOnboarding] = useState<TTutor>({
    profile_image: '',
    first_name: '',
    last_name: '',
    gender: '',
    country: '',
    state: '',
    onboarding_complete: false,
    headline: '',
    bio: '',
    linkedIn_profile: '',
  })

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      const onboardingFormData = new FormData()

      Object.entries({ ...onboarding, onboarding_complete: true }).forEach(
        ([key, value]) => {
          console.log(key, value)
          onboardingFormData.append(key, value?.toString() || '')
        }
      )

      await completeProfile(onboardingFormData)
      const { data: tutor } = await getProfile()

      setUser(tutor)

      navigate(ERoutes.DASHBOARD)
    } catch (error) {
      const errorMessage = errors.format(error)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Layout>
      <ProfileInformation
        onboarding={onboarding}
        setOnboarding={setOnboarding}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </Layout>
  )
}

export default Onboarding
