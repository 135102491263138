import { useAuth } from 'Context/AuthUserContext'
import SupportCantFind from '../SupportCantFind/SupportCantFind'
import SupportFaqs from '../SupportFaqs/SupportFaqs'
import SupportVisitOurCommunity from '../SupportVisitOurCommunity/SupportVisitOurCommunity'
import classes from './SupportContainer.module.css'

const SupportContainer = () => {
  const { user } = useAuth()

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        Hi {user?.first_name || 'user'}, how can we help?
      </div>

      <SupportFaqs />
      <SupportVisitOurCommunity />
      <SupportCantFind />
    </section>
  )
}

export default SupportContainer
