import { TrashIcon } from '@heroicons/react/24/outline'

import Button from 'Components/Button/Button'
import Input from 'Components/Input/Input'
import Loader from 'Components/Loader/Loader'
import AcceptedModal from 'Components/Modals/AcceptedModal/AcceptedModal'
import TextArea from 'Components/TextArea/TextArea'
import DeleteModalBody from 'Containers/CreatingCourseModulePageContainer/DeleteModalBody'
import DiscardModalBody from 'Containers/CreatingCourseModulePageContainer/DiscardModalBody'
import { useApp } from 'Context/AppContext'
import { inputChangeHandler } from 'HelperFunctions/inputChangeHandler'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  addLessonToWeekById,
  deleteLesson,
  TLessonContentDetails,
  updateLessonToWeekById,
} from 'Services/Courses'
import endpoints from 'Services/Courses/endpoints'
import useCourse from 'Services/Courses/Hooks/useCourse'
import { mutate } from 'swr'
import errors from 'Utilities/errors'

const FinalExam = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const lessonId = searchParams.get('lesson-id')
  const weekId = searchParams.get('week-id')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [data, setData] = useState({
    title: '',
    instructions: '',
    link: '',
  })
  const [showDiscardModal, setShowDiscardModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { setNotifications } = useApp()
  const { useGetLesson } = useCourse()

  const { data: lessonResponse, isLoading } = useGetLesson<
    (TLessonContentDetails & { instructions: string; link: string })[]
  >(lessonId, 'final-exam')

  useEffect(() => {
    // setData here
    setData({
      title: lessonResponse?.data[0].title || '',
      instructions: lessonResponse?.data[0].instructions || '',
      link: lessonResponse?.data[0].link || '',
    })
  }, [lessonResponse?.data])

  const handleReset = () => {
    setSearchParams((prevState) => {
      prevState.delete('lesson-id')
      prevState.delete('lesson-type')
      prevState.set('lesson-step', '1')
      return prevState
    })
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      if (lessonId) {
        await updateLessonToWeekById(lessonId, 'final-exam', data)

        setNotifications([
          {
            title: 'Successfully updated final-exam',
            severity: 'success',
            id: Date.now(),
          },
        ])
      } else {
        await addLessonToWeekById(weekId as string, 'final-exam', data)

        setNotifications([
          {
            title: 'Successfully added final-exam to week',
            severity: 'success',
            id: Date.now(),
          },
        ])
      }

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setSearchParams((prevState) => {
        prevState.delete('lesson-id')
        prevState.delete('lesson-type')
        prevState.set('lesson-step', '1')
        return prevState
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  const validateInputs = (payload: any) => {
    return Object.values(payload).every((item) => item)
  }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await deleteLesson(lessonId as string, 'final-exam')

      setNotifications([
        {
          title: 'Successfully deleted final-exam',
          severity: 'success',
          id: Date.now(),
        },
      ])

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      handleReset()
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsDeleting(false)
    }
  }

  if (isLoading) {
    return (
      <div className="w-full bg-white rounded-md">
        <Loader />
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="bg-white rounded-md px-8 pb-5">
        <h2 className="text-[31px] font-medium pt-6 mb-2">
          Enter final exam details
        </h2>

        <hr className="mb-6" />

        <Input
          isRequired
          name="title"
          label="Add title"
          placeholder="Untitled"
          value={data.title}
          onChange={(e) => inputChangeHandler(e, setData)}
        />

        <TextArea
          isRequired
          name="instructions"
          label="Add instruction"
          placeholder="Add instruction for your students here"
          value={data.instructions}
          onChange={(e) => inputChangeHandler(e, setData)}
        />

        <Input
          isRequired
          name="link"
          label="Enter URL"
          placeholder="Enter the link here"
          value={data.link}
          onChange={(e) => inputChangeHandler(e, setData)}
        />
      </div>

      <div className="bg-white mt-8 rounded-md flex items-center py-6 pr-8">
        {lessonId && (
          <button
            className="text-rose-500 flex items-center font-medium gap-x-3"
            onClick={() => setShowDeleteModal(true)}
          >
            <TrashIcon />

            <span>Delete lesson</span>
          </button>
        )}

        <div className="flex items-center gap-x-6 ml-auto">
          <Button type="secondary" onClick={() => setShowDiscardModal(true)}>
            Discard changes
          </Button>

          <Button
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!validateInputs(data)}
          >
            Save lesson
          </Button>
        </div>
      </div>

      {/* Modals */}
      {showDiscardModal && (
        <AcceptedModal
          onClick={() => {
            setShowDiscardModal(false)
          }}
          body={
            <DiscardModalBody
              onClick={() => {
                setShowDiscardModal(false)
              }}
              onClick2={handleReset}
            />
          }
        />
      )}

      {showDeleteModal && (
        <AcceptedModal
          onClick={() => {
            setShowDeleteModal(false)
          }}
          body={
            <DeleteModalBody
              caption={`Are you sure you want to delete ${data.title}`}
              isDeleting={isDeleting}
              onClick={() => {
                setShowDeleteModal(false)
              }}
              onClick2={handleDelete}
            />
          }
        />
      )}
    </div>
  )
}

export default FinalExam
