import classes from './CourseDetailsContainer.module.css'
import frontendbyfemiadelana from '../../Assets/Images/frontendbyfemiadelana.png'
import { useContext } from 'react'
import { AuthUserContext } from '../../Context/AuthUserContext'
import { capitalize } from '../../HelperFunctions/capitalize'
import Svgs from 'Assets/Svgs'

type CourseDetailsContainerTypes = {
  courseData: any
}

const CourseDetailsContainer = ({
  courseData,
}: CourseDetailsContainerTypes) => {
  // Context
  const { user } = useContext(AuthUserContext)

  const courseDetails = [
    {
      courseName: 'Frontend Development',
      instructorName: 'Femi Adelana',
      courseDescription: `You have filIgnite your business potential with our 
                resources at ise School of Business. Gain the 
                knowledge and skills to thrive in the dynamic world of 
                commerce. Lead and achieve greatness with essential 
                business skills.led in the answer for this form`,
      courseImage: frontendbyfemiadelana,
    },
  ]
  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>
        <div>
          <div className="text-[#664EFE]">
            <Svgs.StarIcon />
          </div>

          <h3>Course details</h3>
        </div>
      </div>
      {courseDetails.map((data, i) => {
        return (
          <div key={i} className={classes.subContainer}>
            <div>
              <span>Course Name</span>
              <p>{courseData?.name}</p>
            </div>
            <div>
              <span>Instructor Name</span>
              <p>
                {capitalize(user?.first_name || '')}{' '}
                {capitalize(user?.last_name || '')}
              </p>
            </div>
            <div>
              <span>Course Description</span>
              <p>{courseData?.description}</p>
            </div>
            <div>
              <span>Course Image</span>
              <img src={courseData?.cover_image} alt={data.courseName} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CourseDetailsContainer
