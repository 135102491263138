import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as ReadingIcon } from './reading-icon.svg'
import { ReactComponent as PresentationIcon } from './presentation-icon.svg'
import { ReactComponent as VideoIcon } from './video-icon.svg'
import { ReactComponent as DragIndicator } from './drag-indicator.svg'
import { ReactComponent as AssessmentToolsIcon } from './assessment-tools-icon.svg'
import { ReactComponent as CurriculumIcon } from './curriculum-icon.svg'
import { ReactComponent as StarIcon } from './star-icon.svg'

const Svgs = {
  Arrow,
  ReadingIcon,
  PresentationIcon,
  VideoIcon,
  DragIndicator,
  AssessmentToolsIcon,
  CurriculumIcon,
  StarIcon,
}

export default Svgs
