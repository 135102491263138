import { SetStateAction, useState, Dispatch, useEffect } from 'react'
import { BlockCard, BlockContent, BlockHeader } from './Block'
import { Editor } from '@monaco-editor/react'
import { StateEffect } from '@codemirror/state'

interface ICustomizedLesson {
  title: string
  blocks: { type: string; value: string }[]
}

const CodeBlock = (props: {
  value: string
  setValue: Dispatch<SetStateAction<ICustomizedLesson>>
  blockIndex: number
}) => {
  const { value, setValue, blockIndex } = props
  const [code, setCode] = useState('')

  useEffect(() => {
    setCode(value)
  }, [])

  const handleSetValue = (val: any) => {
    setCode(val)
    setValue((state) => ({
      ...state,
      blocks: state.blocks.map((block, i) => {
        if (blockIndex === i) {
          return { ...block, value: val }
        }
        return block
      }),
    }))
  }

  const handleDelete = () => {
    setValue((state) => ({
      ...state,
      blocks: state.blocks.filter((_, i) => blockIndex !== i),
    }))
  }

  return (
    <BlockCard>
      <BlockHeader title="Code example" onDelete={handleDelete} />

      <BlockContent>
        {/* <CodeEditor value={code} onChange={(val) => {}} /> */}
        <div className="pb-2">
          <Editor
            height="400px"
            defaultLanguage="javascript"
            defaultValue={code}
            className="border"
            onChange={handleSetValue}
          />
        </div>
      </BlockContent>
    </BlockCard>
  )
}

export default CodeBlock
