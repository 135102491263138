import { Link, useNavigate } from 'react-router-dom'

import loginSvg from '../../Assets/Images/login.svg'
import iseLogo from '../../Assets/Images/iseLogo.svg'
import Input from '../../Components/Input/Input'
import Button from '../../Components/Button/Button'
import OnboardingLayout from '../../Components/OnboardingLayout/OnboardingLayout'
import Error from '../../Components/Error/Error'
import { capitalize } from '../../HelperFunctions/capitalize'

import classes from './Login.module.css'
import { useState } from 'react'
import { login } from 'Services'
import { ERoutes } from 'Router/Routes'
import { useAuth } from 'Context/AuthUserContext'

const Login = () => {
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [auth, setAuth] = useState({ email: '', password: '' })

  const navigate = useNavigate()
  const { setUser, setDefaultPassword } = useAuth()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event

    setAuth((state) => ({ ...state, [target.name]: target.value }))
  }

  type TSetTokenKey = 'iseTutorAccessToken' | 'iseTutorRefreshAccessToken'

  const setToken = (key: TSetTokenKey, value: string) => {
    localStorage.setItem(key, value)
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      const { data: response } = await login(auth)

      setToken('iseTutorAccessToken', response.accessToken)
      setToken('iseTutorRefreshAccessToken', response.refreshToken)
      setUser(response.tutor)

      if (response.tutor.is_default_password) {
        setDefaultPassword(auth.password)
        navigate(ERoutes.SET_PASSWORD)
        return
      }

      if (!response.tutor.onboarding_complete) {
        navigate(ERoutes.ON_BOARDING)
        return
      }

      navigate(ERoutes.DASHBOARD)
    } catch (error: any) {
      const errorMessage = error.response.data.responseMessage

      setError(errorMessage)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <OnboardingLayout image={loginSvg}>
      <section className={classes.container}>
        <img src={iseLogo} alt="Ise Logo" className={classes.logo} />

        <div className={classes.feedback}>
          {error && <Error type="error">{capitalize(error as string)}</Error>}
        </div>

        <div className={classes.header}>
          <h4>Welcome to iṣẹ́ Edtech</h4>
          <p>Login with the details sent to your email</p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Input
            label="Email"
            isRequired
            placeholder="name@gmail.com"
            name="email"
            value={auth.email}
            onChange={handleChange}
          />

          <span>
            <Input
              label="Password"
              isRequired
              type="password"
              placeholder="************"
              name="password"
              value={auth.password}
              onChange={handleChange}
            />
          </span>

          <div className={classes.rememberAndForgot}>
            <div>
              <input type="checkbox" id="remember" />
              <span>Remember Me</span>
            </div>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <div className={classes.buttonSection}>
            <Button onClick={handleSubmit} loading={isSubmitting}>
              <span>Log in</span>
            </Button>
          </div>
        </form>
      </section>
    </OnboardingLayout>
  )
}

export default Login
