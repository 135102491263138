import Input from 'Components/Input/Input'
import classes from './CourseCustomize.module.css'
import Button from 'Components/Button/Button'
import AcceptedModal from 'Components/Modals/AcceptedModal/AcceptedModal'
import DeleteModalBody from 'Containers/CreatingCourseModulePageContainer/DeleteModalBody'
import DiscardModalBody from 'Containers/CreatingCourseModulePageContainer/DiscardModalBody'
import Toast from 'Components/Toast/Toast'
import SelectTypeModal from './SelectTypeModal/SelectTypeModal'

import AddVideoBlock from './AddVideoBlock/AddVideoBlock'
import AddEmbedVideo from './AddEmbedVideo/AddEmbedVideo'
import AddCodeExample from './AddCodeExample/AddCodeExample'
import AddTextAndImage from './AddTextAndImage/AddTextAndImage'
import { TrashIcon } from '@heroicons/react/24/outline'
import TextAndImageBlock from './Components/TextAndImageBlock'
import CodeBlock from './Components/CodeBlock'
import VideoBlock from './Components/VideoBlock'
import { useEffect, useState } from 'react'
import { addLessonToWeekById, updateLessonToWeekById } from 'Services/Courses'
import { useParams, useSearchParams } from 'react-router-dom'
import { useApp } from 'Context/AppContext'
import errors from 'Utilities/errors'
import endpoints from 'Services/Courses/endpoints'
import { mutate } from 'swr'
import useCourse from 'Services/Courses/Hooks/useCourse'

// sample todo app

// const todoApp = () => {
//   const todos = []

//   const addTodo = (todo) => {
//     todos.push(todo)
//   }

//   const deleteTodo = (todoIndex) => {
//     todos.filter((_, i) => i !== todoIndex)
//   }

//   const updateTodo = (payload) => {
//     todos.map((todo, i) => {
//       if (i === todoIndex) {
//         return { ...todo, payload }
//       }
//       return todo
//     })
//   }

//   const getAllTodos = () => {
//     return todos
//   }

//   const getTodo = (todoIndex) => {
//     return todos.find((_, i) => todoIndex === i)
//   }
// }

interface ICustomizedLesson {
  id?: number
  title: string
  blocks: { type: string; value: string }[]
}

const BlockSelector = (props: {
  blockIndex: number
  blockType: string
  blockValue: string
  setBlockValue: any
}) => {
  const { blockIndex, blockType, blockValue, setBlockValue } = props

  if (blockType === 'text') {
    return (
      <TextAndImageBlock
        value={blockValue}
        setValue={setBlockValue}
        blockIndex={blockIndex}
      />
    )
  }
  if (blockType === 'code') {
    return (
      <CodeBlock
        value={blockValue}
        setValue={setBlockValue}
        blockIndex={blockIndex}
      />
    )
  }
  if (blockType === 'video') {
    return (
      <VideoBlock
        value={blockValue}
        setValue={setBlockValue}
        blockIndex={blockIndex}
      />
    )
  }

  return null
}

const Customize = () => {
  const [displayDiscardModal, setDisplayDiscardModal] = useState(false)
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [displaySelectTypeModal, setDisplaySelectTypeModal] = useState(false)
  const [saveLessonAndContinue, setSaveLessonAndContinue] = useState(1)
  // const [showToast, setShowToast] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const [customizedLesson, setCustomizedLesson] = useState<ICustomizedLesson>({
    title: '',
    blocks: [],
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const weekId = searchParams.get('week-id')
  const lessonId = searchParams.get('lesson-id')
  const { setNotifications } = useApp()
  const { useGetCustomizedLessonById } = useCourse()
  const { data: response } = useGetCustomizedLessonById(lessonId)

  useEffect(() => {
    // console.log('++_____++++>>', customizedLesson)
    setCustomizedLesson({
      title: response?.data.title || '',
      blocks: response?.data.blocks || [],
    })
  }, [response?.data])

  const handleItemClick = (itemTitle: string) => {
    const type =
      {
        'Text and image': 'text',
        'Code Example': 'code',
        Video: 'video',
      }[itemTitle] || ''

    console.log('======>', itemTitle)

    setSelectedItem(itemTitle)
    setCustomizedLesson((state) => ({
      ...state,
      blocks: [...state.blocks, { type, value: '' }],
    }))

    setDisplaySelectTypeModal(false)
  }

  const renderModalBody = () => {
    switch (selectedItem) {
      case 'Text and image':
        return (
          <AddTextAndImage
            onClick={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(true)
            }}
            onClick2={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(false)
            }}
          />
        )
      case 'Embed Video':
        return (
          <AddEmbedVideo
            onClick={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(true)
            }}
            onClick2={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(false)
            }}
          />
        )
      case 'Video':
        return (
          <AddVideoBlock
            onClick={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(true)
            }}
            onClick2={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(false)
            }}
          />
        )
      case 'Code Example':
        return (
          <AddCodeExample
            onClick={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(true)
            }}
            onClick2={() => {
              setSelectedItem(null)
              setDisplaySelectTypeModal(false)
            }}
          />
        )
      default:
        return null
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      if (!lessonId) {
        await addLessonToWeekById<ICustomizedLesson>(
          weekId as string,
          'customized-lesson',
          customizedLesson
        )

        setNotifications([
          {
            title: 'Successfully added customized lesson',
            severity: 'success',
            id: Date.now(),
          },
        ])

        return
      } else {
        await updateLessonToWeekById<ICustomizedLesson>(
          lessonId as string,
          'customized-lesson',
          customizedLesson
        )

        setNotifications([
          {
            title: 'Successfully updated customized lesson',
            severity: 'success',
            id: Date.now(),
          },
        ])
      }

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setSearchParams((prevState) => {
        prevState.delete('lesson-type')
        prevState.delete('lesson-id')
        prevState.set('lesson-step', '1')
        return prevState
      })
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {displayDiscardModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDiscardModal(false)
          }}
          body={
            <DiscardModalBody
              onClick={() => {
                setDisplayDiscardModal(false)
              }}
              onClick2={() => {
                setDisplayDiscardModal(false)
                setSaveLessonAndContinue((prevValue) =>
                  prevValue > 1 ? prevValue - 1 : prevValue
                )
              }}
            />
          }
        />
      )}

      {displayDeleteModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDeleteModal(false)
          }}
          body={
            <DeleteModalBody
              onClick={() => {
                setDisplayDeleteModal(false)
              }}
            />
          }
        />
      )}

      {displaySelectTypeModal && (
        <>
          <AcceptedModal
            onClick={() => {
              setDisplaySelectTypeModal(false)
            }}
            body={
              <SelectTypeModal
                onClick={() => setDisplaySelectTypeModal(false)}
                onItemClick={handleItemClick}
              />
            }
          />
        </>
      )}

      {/* {selectedItem && (
        <AcceptedModal
          onClick={() => {
            setSelectedItem(null)
            setDisplaySelectTypeModal(false)
          }}
          body={renderModalBody()}
        />
      )} */}

      <div className={`${classes.container} !w-full`}>
        <h1>Untitled lesson</h1>

        <div className={classes.addLesson}>
          <Input
            isRequired
            label="Add lesson title"
            placeholder="Untitled lesson"
            errorMessage="Please enter a valid lesson name"
            value={customizedLesson.title}
            onChange={({ target }) =>
              setCustomizedLesson((state) => ({
                ...state,
                title: target.value,
              }))
            }
          />
        </div>

        {customizedLesson.blocks.length ? (
          <div className="bg-white rounded-lg mt-8 px-7 py-8 space-y-8">
            {customizedLesson.blocks.map((block, i) => {
              return (
                <BlockSelector
                  key={`block__${i}`}
                  blockIndex={i}
                  blockType={block.type}
                  blockValue={block.value}
                  setBlockValue={setCustomizedLesson}
                />
              )
            })}
          </div>
        ) : null}

        <div className={classes.addLesson}>
          <div className={classes.addBlock}>
            <p>You have the knowledge, it’s time to share it.</p>

            <Button
              type="tertiary"
              onClick={() => {
                setDisplaySelectTypeModal(true)
              }}
            >
              <span>Add Block</span>
            </Button>
          </div>
        </div>

        <div className={`${classes.addLesson} ${classes.buttonContainer}`}>
          <Button onClick={handleSubmit} loading={isSubmitting}>
            <span>Save lesson</span>
          </Button>

          <Button
            type="secondary"
            onClick={() => {
              setDisplayDiscardModal(true)
            }}
            className={
              saveLessonAndContinue === 4 ? classes.inactiveSecondary : ''
            }
          >
            <span>Discard changes</span>
          </Button>

          <Button
            onClick={() => {
              setDisplayDeleteModal(true)
            }}
          >
            <TrashIcon />

            <span>Delete lesson</span>
          </Button>
        </div>
      </div>
    </>
  )
}

export default Customize
