import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  deleteCourseModuleById,
  deleteCourseWeekById,
  deleteLesson,
  TCourseModule,
  TCourseWeek,
  TCurriculum,
} from 'Services/Courses'
import { useParams, useSearchParams } from 'react-router-dom'
import Button from 'Components/Button/Button'
import { useApp } from 'Context/AppContext'
import errors from 'Utilities/errors'
import { mutate } from 'swr'
import endpoints from 'Services/Courses/endpoints'
import Svgs from 'Assets/Svgs'

type CourseInfoLeftBarType = {
  curriculum?: TCurriculum
  courseData?: any
}

const LessonItem = (props: {
  lessonName: string
  lessonType: string
  lessonId: string
  weekId: string
}) => {
  const { lessonName, lessonType, lessonId, weekId } = props
  const [_, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const { setNotifications } = useApp()

  const lessonIcon = {
    reading: <Svgs.ReadingIcon />,
    presentation: <Svgs.PresentationIcon />,
    video: <Svgs.VideoIcon />,
    customize: <Svgs.ReadingIcon />,
    quiz: <Svgs.AssessmentToolsIcon />,
    assignment: <Svgs.AssessmentToolsIcon />,
    final_exam: <Svgs.AssessmentToolsIcon />,
  }[lessonType]

  const handleEdit = () => {
    setSearchParams((prevState) => {
      prevState.set('curriculum-step', '3')
      prevState.set('week-id', weekId)
      prevState.set('lesson-step', '3')
      prevState.set(
        'lesson-type',
        lessonType === 'final_exam' ? 'final-exam' : lessonType
      )
      prevState.set('lesson-id', lessonId)
      return prevState
    })
  }

  const handleDelete = async () => {
    try {
      await deleteLesson(
        lessonId,
        lessonType === 'customize'
          ? 'customized-lesson'
          : lessonType === 'final_exam'
          ? 'final-exam'
          : lessonType
      )

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setNotifications([
        {
          title: 'Successfully deleted lesson',
          severity: 'success',
          id: Date.now(),
        },
      ])
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        { title: errorMessage, severity: 'error', id: Date.now() },
      ])
    }
  }

  return (
    <div className="border rounded-md py-4 mt-4 px-4">
      <div className="flex items-center gap-x-2 mb-1">
        <span className="text-[#2e2e2e]">
          <Svgs.DragIndicator />
        </span>
        {lessonName}
      </div>

      <div className="flex items-center pl-8">
        <span className="text-[#2E2E2E]">{lessonIcon ? lessonIcon : null}</span>
        <span className="text-[#737373] text-[13px] mt-1 ml-1">
          <span className="capitalize">
            {lessonType === 'reading' ? 'text' : lessonType}
          </span>{' '}
          . 2 mins
        </span>
      </div>

      <div className="pt-3 ml-8 flex gap-x-2">
        <button
          className="border-2 border-[#664EFE] text-[#664EFE] rounded-md !px-2 !py-0.5 text-xs font-semibold"
          onClick={handleEdit}
        >
          Edit lesson
        </button>

        <button
          className="border-2 border-rose-500 text-rose-500 rounded-md !px-2 !py-1 text-xs font-semibold"
          onClick={handleDelete}
        >
          Delete lesson
        </button>
      </div>
    </div>
  )
}

const WeekItem = (props: {
  courseWeek: TCourseWeek
  courseModuleId: string
}) => {
  const { courseWeek, courseModuleId } = props
  const [isOpen, setIsOpen] = useState(true)
  const [_, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const { setNotifications } = useApp()

  const handleEdit = () => {
    setSearchParams({
      'curriculum-step': '2',
      'module-id': courseModuleId,
      'week-id': courseWeek.id.toString(),
    })
  }

  const handleAddLesson = () => {
    setSearchParams({
      'curriculum-step': '3',
      'module-id': courseModuleId,
      'week-id': courseWeek.id.toString(),
      'lesson-step': '2',
    })
  }

  // TODO: handle submitting and disabled state
  const handleDelete = async () => {
    try {
      await deleteCourseWeekById(courseWeek.id.toString())

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setNotifications([
        {
          title: 'Successfully deleted week',
          severity: 'success',
          id: Date.now(),
        },
      ])
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        {
          title: errorMessage,
          severity: 'error',
          id: Date.now(),
        },
      ])
    }
  }

  return (
    <div className="border-b py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <Svgs.DragIndicator />
          {courseWeek?.title}
        </div>

        <div
          className="transition duration-500"
          style={{ transform: isOpen ? 'rotateX(-180deg)' : 'rotateX(0deg)' }}
          onClick={() => setIsOpen((state) => !state)}
        >
          <ChevronDownIcon />
        </div>
      </div>

      <div className="pt-2 ml-8 flex gap-x-2">
        <button
          className="bg-[#664EFE] text-white rounded-md !px-2 !py-0.5 text-xs font-semibold"
          onClick={handleAddLesson}
        >
          Add lesson
        </button>

        <button
          className="border-2 border-[#664EFE] text-[#664EFE] rounded-md !px-2 !py-0.5 text-xs font-semibold"
          onClick={handleEdit}
        >
          Edit week
        </button>

        <button
          className="border-2 border-rose-500 text-rose-500 rounded-md !px-2 !py-1 text-xs font-semibold"
          onClick={handleDelete}
        >
          Delete week
        </button>
      </div>

      {isOpen && (
        <div>
          {courseWeek?.content?.map((lesson) => (
            <LessonItem
              lessonName={lesson?.title || ''}
              lessonType={lesson?.type || ''}
              lessonId={lesson?.details?.id?.toString() || ''}
              weekId={courseWeek?.id?.toString() || ''}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const ModuleItem = (props: {
  courseModule: TCourseModule
  disabled?: boolean
}) => {
  const { courseModule, disabled } = props
  const [isOpen, setIsOpen] = useState(true)
  const [_, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const { setNotifications } = useApp()

  const handleAddWeek = () => {
    setSearchParams({
      'curriculum-step': '2',
      'module-id': courseModule.id.toString(),
    })
  }

  const handleEdit = () => {
    setSearchParams({
      'curriculum-step': '1',
      'module-id': courseModule.id.toString(),
    })
  }

  // TODO: handle submitting and disabled state
  const handleDelete = async () => {
    try {
      await deleteCourseModuleById(courseModule.id.toString())

      mutate(endpoints.GET_CURRICULUM_BY_COURSE_ID(courseId as string))

      setNotifications([
        {
          title: 'Successfully deleted week',
          severity: 'success',
          id: Date.now(),
        },
      ])
    } catch (err) {
      const errorMessage = errors.format(err)

      setNotifications([
        {
          title: errorMessage,
          severity: 'error',
          id: Date.now(),
        },
      ])
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-lg font-medium">{courseModule?.title}</div>

        <div
          className="transition duration-500"
          style={{ transform: isOpen ? 'rotateX(-180deg)' : 'rotateX(0deg)' }}
          onClick={() => setIsOpen((state) => !state)}
        >
          <ChevronDownIcon />
        </div>
      </div>

      <hr className="mt-4" />

      {isOpen && (
        <div>
          {courseModule?.course_weeks?.map((courseWeek) => (
            <WeekItem
              courseModuleId={courseModule.id.toString()}
              courseWeek={courseWeek}
            />
          ))}
        </div>
      )}

      <div
        className={`flex gap-x-3 w-full font-medium ${
          disabled ? 'text-[#B9B9B9]' : 'text-[#664EFE]'
        }  pt-4`}
      >
        <button
          className={`${
            disabled ? 'bg-[#B9B9B9] text-gray-100' : 'bg-[#664EFE] text-white'
          }  rounded-md flex items-center justify-center gap-x-2 text-sm !p-0 h-[40px] flex-grow`}
          disabled={disabled}
          onClick={handleAddWeek}
        >
          Add Week
        </button>

        <button
          className={`border-2 ${
            disabled ? 'border-[#B9B9B9]' : 'border-[#664EFE]'
          }  rounded-md flex items-center justify-center gap-x-2 text-sm !p-0 h-[40px] flex-grow`}
          disabled={disabled}
          onClick={handleEdit}
        >
          Edit module
        </button>

        <button
          className={`border-2 ${
            disabled
              ? 'border-[#B9B9B9] text-[#B9B9B9]'
              : 'border-rose-500 text-rose-500'
          } rounded-md flex items-center justify-center gap-x-2 text-sm !p-0 py-3 flex-grow`}
          disabled={disabled}
          onClick={handleDelete}
        >
          Delete module
        </button>
      </div>
    </div>
  )
}

const CurriculumSidebar = ({ curriculum }: CourseInfoLeftBarType) => {
  // States
  const [_, setSearchParams] = useSearchParams()

  const handleAddModule = () => {
    setSearchParams({ 'curriculum-step': '1' })
  }

  return (
    <div
      className={`max-h-[70vh] w-full md:w-[452px] bg-white rounded-[10px] shrink-0 overflow-y-auto`}
    >
      <div className="sticky top-0 h-[50px] border-b px-6 flex items-center bg-white mb-5 z-20">
        <h3 className="text-xl font-medium">{curriculum?.title}</h3>
      </div>

      <div className="px-6 space-y-5">
        {!curriculum?.course_modules?.length ? (
          <div className="border px-3.5 py-4 rounded-md">
            <ModuleItem
              disabled
              courseModule={
                { title: 'Module 1: Untitled module', course_weeks: [] } as any
              }
            />
          </div>
        ) : (
          <>
            {curriculum?.course_modules?.map((courseModule) => (
              <div className="border px-3.5 py-4 rounded-md">
                <ModuleItem courseModule={courseModule} />
              </div>
            ))}
          </>
        )}
      </div>

      <section className="border-t h-[80px] w-full flex items-center justify-center sticky bottom-0  bg-white mt-10">
        <Button
          disabled={!curriculum?.course_modules?.length}
          onClick={handleAddModule}
        >
          <span className="text-base font-medium">Add new module</span>
        </Button>
      </section>
    </div>
  )
}

export default CurriculumSidebar
