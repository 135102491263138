import { useContext } from 'react'
import Button from '../../Components/Button/Button'
import GetStartedVideoContainer from '../GetStartedVideoContainer/GetStartedVideoContainer'
import classes from './CreateYourCurriculumContainer.module.css'
import { AppContext } from '../../Context/AppContext'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CurricullumAccordion from './CurricullumAccordion'
import { ArrowRightIcon, EyeIcon } from '@heroicons/react/24/outline'
import Svgs from 'Assets/Svgs'

type CreateYourCurriculumContainerType = {
  curricullum: any
  courseData: any
}

const CreateYourCurriculumContainer = ({
  curricullum,
  courseData,
}: CreateYourCurriculumContainerType) => {
  const { showGetStarted } = useContext(AppContext)

  //   Router
  const { courseId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isEditing = searchParams.get('isEditing')

  const handleCreateOutlineClick = () => {
    if (!curricullum?.id) {
      navigate(`/courses/${courseId}/create-curricullum`)
    } else {
      navigate(
        `/courses/${courseId}/curriculum/${curricullum?.id}?curriculum-step=1`
      )
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>
        <div>
          <div className="text-[#664EFE]">
            <Svgs.CurriculumIcon />
          </div>

          <div>
            <h3>
              {!curricullum?.course_modules
                ? 'Create your curriculum'
                : 'Course curriculum'}
            </h3>

            {isEditing && (
              <p>
                {courseData?.cohorts?.length > 0
                  ? courseData?.cohorts[0]?.name
                  : ''}
              </p>
            )}
          </div>
        </div>
        {isEditing && (
          <Button
            onClick={() =>
              navigate(
                `/courses/${courseId}/curriculum/${curricullum.id}/preview`
              )
            }
          >
            <EyeIcon className="h-[25px]" />
            <span>Preview curriculum</span>
          </Button>
        )}
      </div>

      {!curricullum?.course_modules?.length ? (
        <div className={classes.subContainer}>
          <h4>Curriculum outline</h4>

          <>
            <p>
              Create an outline by structuring your content into weeks and
              lessons. Then, add a wide array of content to your lessons.
            </p>

            <Button onClick={handleCreateOutlineClick}>
              <span>Create outline</span>

              <ArrowRightIcon className="h-[19px]" />
            </Button>
          </>
        </div>
      ) : null}

      {!curricullum?.course_modules?.length ? (
        <div className={classes.getStartedVideo}>
          {showGetStarted.dashboard && (
            <GetStartedVideoContainer
              title="Quick Start Guide"
              paragraph="Welcome to the course creation journey! This Quick Start Guide will walk you through the essential steps to kickstart your course outline. Let' s make your curriculum shine!"
              videoHeight="280px"
            />
          )}
        </div>
      ) : null}

      {curricullum?.course_modules?.length ? (
        <CurricullumAccordion curriculum={curricullum} />
      ) : null}
    </div>
  )
}

export default CreateYourCurriculumContainer
