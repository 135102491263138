type TAnalyticCardProps = {
  title: string
  value: number | string
  icon: any
}

const AnalyticsCard = (props: TAnalyticCardProps) => {
  const { title, value, icon } = props

  return (
    <div className="bg-white w-1/3 flex items-center justify-between px-6 py-4 rounded-lg">
      <div>
        <h4 className="text-lg font-medium">{title}</h4>
        <p className="text-[31px] font-medium">{value}</p>
      </div>

      <img src={icon} alt="analytic_image" className="w-[40px] h-[40px]" />
    </div>
  )
}

export default AnalyticsCard
