import Layout from 'Components/Layout/Layout'
import CurriculumSidebar from './Components/CurriculumSidebar'
import CurriculumHeader from './Components/CurriculumHeader'
import Loader from 'Components/Loader/Loader'
import { useParams, useSearchParams } from 'react-router-dom'
import useCourse from 'Services/Courses/Hooks/useCourse'

import classes from './Curriculum.module.css'
import CurriculumModule from './Components/CurriculumModule'
import CurriculumWeek from './Components/CurriculumWeek'
import CurriculumLesson from './Components/CurriculumLesson'
import { useEffect } from 'react'
// import SelectLesson from './Components/CurriculumLesson/SelectLesson/SelectLesson'

const Curriculum = () => {
  const { courseId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { useGetCurriculumByCourseId } = useCourse()
  // Requests
  const { isLoading, data } = useGetCurriculumByCourseId(courseId || null)

  const curriculum = data?.data

  const curriculumStep = searchParams.get('curriculum-step')

  enum CurriculumSteps {
    MODULE = 1,
    WEEK,
    LESSON,
  }

  useEffect(() => {
    if (!curriculumStep) {
      setSearchParams({ 'curriculum-step': '1' })
    }
  }, [])

  return (
    <Layout closeSideNav>
      <div className={classes.container}>
        <CurriculumHeader />

        <div className={classes.body}>
          <CurriculumSidebar curriculum={curriculum} />

          {CurriculumSteps.MODULE === Number(curriculumStep) && (
            <CurriculumModule />
          )}

          {CurriculumSteps.WEEK === Number(curriculumStep) && (
            <CurriculumWeek />
          )}

          {CurriculumSteps.LESSON === Number(curriculumStep) && (
            <CurriculumLesson />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Curriculum
