import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { getProfile, TTutor } from 'Services'
import { ERoutes } from 'Router/Routes'
import errors from 'Utilities/errors'
import { useApp } from './AppContext'

export type requestType = {
  isLoading: boolean
  data?: null | any[] | string | any
  error?: null | any
  id?: string
}

type AuthCOntextValuesProps = {
  user: TTutor | undefined
  setUser: Dispatch<SetStateAction<TTutor | undefined>>
  isLoading: boolean
  defaultPassword: string
  setDefaultPassword: Dispatch<SetStateAction<string>>
  logout: () => void
}

type AuthCOntextProviderProps = {
  children: React.ReactNode
}

export const AuthUserContext = createContext({} as AuthCOntextValuesProps)

const AuthUserContextProvider = ({ children }: AuthCOntextProviderProps) => {
  // Local
  const userToken = localStorage.getItem('iseTutorAccessToken')

  //   Router
  const navigate = useNavigate()
  const location = useLocation()

  const { setNotifications } = useApp()

  const logout = () => {
    localStorage.removeItem('iseTutorAccessToken')
    localStorage.removeItem('iseTutorRefreshToken')

    navigate(ERoutes.LOGIN, { state: location.pathname })
  }

  // new stuff
  const [user, setUser] = useState<TTutor>()
  const [defaultPassword, setDefaultPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userToken) {
      setIsLoading(true)

      getProfile()
        .then((res) => {
          setUser(res.data)

          if (!res.data.onboarding_complete) {
            navigate(ERoutes.ON_BOARDING)
          }
        })
        .catch((err) => {
          const errorMessage = errors.format(err)

          setNotifications([
            { id: Date.now(), severity: 'error', title: errorMessage },
          ])
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  return (
    <AuthUserContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        defaultPassword,
        setDefaultPassword,
        logout,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  )
}

export const useAuth = () => useContext(AuthUserContext)

export default AuthUserContextProvider
