import FAQDropdown from '../../Components/FAQDropdown/FAQDropdown'
import classes from './SupportFaqs.module.css'

const SupportFaqs = () => {
  const faqs = [
    {
      question: 'How can I upload course materials to the platform?',
      answer: `On your dashboard sidebar, select the option "Courses." You'll find the option to "Create course" and follow the steps to upload course materials.`,
    },

    {
      question: 'Can I customise the appearance of my course on the platform?',
      answer: `Yes, you can customise your course appearance. Under the "Courses" option, you can customise the cover of your course with any picture of your choice.`,
    },
    {
      question: 'Where can I find information about a specific student?',
      answer: `Visit the "Student" section to access all your student information. Click on the "View details" option for a more detailed information about each student.`,
    },
    {
      question: 'Can I schedule a tutoring session with a student?',
      answer: `Yes. You can schedule a session with a student by sending them a message via the community channel.`,
    },
    {
      question:
        'What types of files can I upload as study resources for my students?',
      answer: `You can upload a variety of file types, including documents (PDFs, Word), videos, links, and more.`,
    },
  ]

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <h4>Explore Frequently asked questions</h4>
        <div>
          {faqs.map((data, i) => {
            return (
              <div key={i} className={classes.faq}>
                <FAQDropdown question={data.question} answer={data.answer} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SupportFaqs
